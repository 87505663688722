<template>
    <div class="container">
        <div v-if="!isMobile" class="pcWrapper">
            <div class="relationsWrapper">
                <div class="bannerWrapper">
                    <img src="../../assets/images/relations/banner.png" />
                    <!-- <div class="bannerTextWrapper">
                      <span class="bannerText1">行业格局已经发生巨变</span>
                      <span class="bannerText2">我们为您提供场景化的</span>
                      <span class="bannerText3">深度行业解决方案</span>
                    </div> -->

                    <div class="bannerTextWrapper">
                        <span class="bannerText1">与金融工程师、技术工程师</span>
                        <span class="bannerText2">共同规划科技前沿应用</span>
                        <!-- <span class="bannerText3">深度行业解决方案</span> -->
                    </div>
                </div>
                <div class="announcementWrapper">
                    <div class="top_box">
                        <div class="pc_change">
                            <div>
                                <a @click.prevent="jump('anc')">公司公告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('gov')">公司治理</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('acv')">业绩报告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('ser')">投资者服务</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mapWrapper">
                    <div class="investor-index-s1">
                        <div class="wrapper">
                            <div class="inner-tit gray">
                                <h3 class="h3">公司公告</h3>
                            </div>
                            <div class="cont">
                                <div class="img-box">
                                    <img src="../../assets/images/relations/annonce_S_back.png" alt="公司公告">
                                </div>
                                <div class="txt-box">
                                    <div v-for="announcement in announcements" :key="announcement.id"
                                        class="announcement">
                                        <a :href="announcement.url" target="_blank">
                                            <div class="content">{{ announcement.content }}</div>
                                            <div class="date">{{ announcement.date }}</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <a href="#/Announce?category=anc" class="inverstor-more">了解详情</a>
                        </div>
                    </div>
                    <div class="investor-index-s2">
                        <div class="wrapper">
                            <div class="inner-tit gray">
                                <h3 class="h3">公司治理</h3>
                            </div>
                            <div class="government_box">
                                <!--                                <div class="item">-->
                                <!--                                    <div class="img_box">-->
                                <!--                                        <img src="../../assets/images/relations/milepost_back.png" alt="股东大会">-->
                                <!--                                    </div>-->
                                <!--                                    <span>01</span>-->
                                <!--                                    <div class="txt-box">-->
                                <!--                                        <h3 class="h3">股东大会</h3>-->
                                <!--                                        <p>艾融软件股东大会的介绍信息</p>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <div class="item" @click="toGover('director')">
                                    <div class="img_box">
                                        <img src="../../assets/images/relations/shareholder_back.png" alt="董事会">
                                    </div>
                                    <span>01</span>
                                    <div class="txt-box">
                                        <h3 class="h3">董事会</h3>
                                        <p>艾融软件董事会的介绍信息</p>
                                    </div>
                                </div>
                                <div class="item" @click="toGover('supervisor')">
                                    <div class="img_box">
                                        <img src="../../assets/images/relations/supervisor_back.png" alt="监事会">
                                    </div>
                                    <span>02</span>
                                    <div class="txt-box">
                                        <h3 class="h3">监事会</h3>
                                        <p>艾融软件监事会的介绍信息</p>
                                    </div>
                                </div>
                                <div class="item" @click="toGover('senior')">
                                    <div class="img_box">
                                        <img src="../../assets/images/relations/senior_back.png" alt="高级管理层">
                                    </div>
                                    <span>03</span>
                                    <div class="txt-box">
                                        <h3 class="h3">高级管理层</h3>
                                        <p>艾融软件高级管理层的介绍信息</p>
                                    </div>
                                </div>
                                <div class="item" @click="toGover('consti')">
                                    <div class="img_box">
                                        <img src="../../assets/images/relations/milepost_back.png" alt="公司章程">
                                    </div>
                                    <span>04</span>
                                    <div class="txt-box">
                                        <h3 class="h3">公司章程</h3>
                                        <p>艾融软件公司章程的介绍信息</p>
                                    </div>
                                </div>
                            </div>
                            <a href="#/government?category=gov" class="inverstor-more" style="margin-left: 4%;">了解详情</a>
                        </div>
                    </div>
                    <div class="investor-index-s3">
                        <div class="wrapper">
                            <div class="inner-tit white">
                                <h3 class="h3">业绩报告</h3>
                            </div>
                            <div class="conts">
                                <div class="cont">
                                    <div class="tab-tit">
                                        <div class="cell-box" @click="func('annual')"
                                            v-bind:class="{ 'cell-box_active': (active == 'annual') }">
                                            <div class="cell">
                                                <h3 class="h3">年报</h3>
                                                <h4 class="h4">Annual Report</h4>
                                            </div>
                                        </div>
                                        <div class="item cell-box" @click="func('Inter')"
                                            v-bind:class="{ 'cell-box_active': (active == 'Inter') }">
                                            <div class="cell">
                                                <h3 class="h3">中报</h3>
                                                <h4 class="h4">Interim Report</h4>
                                            </div>
                                        </div>
                                        <div class="item cell-box" @click="func('Quart')"
                                            v-bind:class="{ 'cell-box_active': (active == 'Quart') }">
                                            <div class="cell">
                                                <h3 class="h3">季报</h3>
                                                <h4 class="h4">Quarterly Report</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-cont">
                                        <div class="child active">
                                            <div class="items" v-if="block == 'annual'">
                                                <div class="item" v-for="annu in annualList" :key="annu.id">
                                                    <div class="year">2019-2023</div>
                                                    <h3 class="h3">{{ annu.name }}</h3>
                                                    <div class="btns">
                                                        <a :href="annu.to">在线预览</a>
                                                        <a :href="annu.to">下载pdf</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="items" v-if="block == 'Inter'">
                                                <div class="item" v-for="Int in InterimList" :key="Int.id">
                                                    <div class="year">2019-2024</div>
                                                    <h3 class="h3">{{ Int.name }}</h3>
                                                    <div class="btns">
                                                        <a :href="Int.to">在线预览</a>
                                                        <a :href="Int.to">下载pdf</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="items" v-if="block == 'Quart'">
                                                <div class="item" v-for="Qua in QuarterlyList" :key="Qua.id">
                                                    <div class="year">2019-2024</div>
                                                    <h3 class="h3">{{ Qua.name }}</h3>
                                                    <div class="btns">
                                                        <a :href="Qua.to">在线预览</a>
                                                        <a :href="Qua.to">下载pdf</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="#/Achievement?category=acv" class="more">了解详情</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div v-else class="m_wrapper">-->
        <!--            <div class="m_firstBannerWrapper">-->
        <!--                <img src="../../assets/images/relations/phone/firstback.png" alt=""/>-->
        <!--                <div class="m_textBox">-->
        <!--                    <div class="firstRow1">与金融工程师、技术工程师</div>-->
        <!--                    <div class="firstRow2">共同规划科技前沿应用</div>-->
        <!--                    &lt;!&ndash; <div class="firstRow3">深度行业解决方案</div> &ndash;&gt;-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
import { isMobile } from "../../utils/index";

export default {
    data() {
        return {
            isMobile: false,
            annualList: [],
            InterimList: [],
            QuarterlyList: [],
            // achieventList: [],
            announcements: [],
            datas: [],
            page: {
                totle: '',
                currentPage: 2,
                pageSize: 8
            },
            Qua_page: {
                totle: '',
                currentPage: 1,
                pageSize: 3
            },
            active: '',
            block: '',
        };
    },
    filters: {},
    created() {
        this.getData();
        this.getachievent();
        this.func("annual");
    },
    mounted() {
        this.isMobile = isMobile();
        window.addEventListener("scroll", this.getScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.getScroll);
    },
    methods: {
        // 公司治理跳转
        toGover(a) {
            console.log("a", a);
            if (a != 'consti') {
                this.$router.push({ path: "/government?#gov", query: { location: a } });
            } else {
                this.$router.push({ path: "/government?#consti", query: { location: a } });
            }
        },
        // tab切换
        func(c) {
            this.active = c;
            this.block = c;
        },
        // 跳转
        jump(type) {
            if (type == "anc") {
                this.$router.push({ path: "/Announce", query: { category: type } });
            } else if (type == "gov") {
                this.$router.push({ path: "/government", query: { category: type } });
            } else if (type == "acv") {
                this.$router.push({ path: "/Achievement", query: { category: type } });
            } else if (type == "ser") {
                this.$router.push({ path: "/Service", query: { category: type } });
            }
        },
        //获取数据并处理
        getData() {
            this.$axios.get("/parameter/query").then((res) => {
                this.datas = res.data.data;
                const datas = this.datas;
                this.page.totle = datas.length;
                console.log("长度:" + this.page.totle);
                this.announcements = datas.slice(0, 8);
                console.log("条数", this.announcements);
            })
        },
        // 获取业绩表
        getachievent() {
            // 年报业报
            this.$axios.get("/parameter/annualList").then((res) => {
                const datas = res.data.data;
                // this.annualList = res.data.data;
                // 投资者关系底部-业绩报告，切换卡片默认年报只展示三条数据
                this.annualList = datas.slice((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize, this.Qua_page.pageSize + ((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize));
                console.log("年报业报", this.annualList)
            })
            // 中报业报
            this.$axios.get("/parameter/InterimList").then((res) => {
                const datas = res.data.data;
                // this.InterimList = res.data.data;
                // 投资者关系底部-业绩报告，切换卡片默认只展示中报三条数据
                this.InterimList = datas.slice((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize, this.Qua_page.pageSize + ((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize));
                console.log("中报业报", this.InterimList)
            })
            // 季报业报
            this.$axios.get("/parameter/QuarterlyList").then((res) => {
                const datas = res.data.data;
                this.QuarterlyList = datas.slice((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize, this.Qua_page.pageSize + ((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize));
                console.log("季报业报", this.QuarterlyList)
            })
        },
        getScroll() {
            // this.indexNumTop = $("#index_num").offset().top;
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
            ) {
                //设置滚动大于300时的执行内容
                // console.log(
                //   "document.documentElement.scrollTop=",
                //   document.documentElement.scrollTop
                // );
                this.$store.commit("TO_AfterScroll");
            } else {
                this.$store.commit("TO_AfterScroll_false");
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.relationsWrapper {
    background-color: #ffffff;

    .bannerWrapper {
        width: 100%;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .bannerTextWrapper {
            position: absolute;
            top: 212px;
            left: 129px;
            display: flex;
            flex-direction: column;

            .bannerText1 {
                color: rgba(174, 57, 56, 100);
                font-size: 40px;
                text-align: left;
                font-family: PingFangSC-Medium;
            }

            .bannerText2 {
                color: rgba(255, 255, 255, 100);
                font-size: 40px;
                text-align: left;
                font-family: PingFangSC-Medium;
            }

            .bannerText3 {
                color: rgba(255, 255, 255, 100);
                font-size: 30px;
                text-align: left;
                font-family: PingFangSC-Light;
            }
        }
    }

    .announcementWrapper {
        fixTitle: true;

        .top_box {
            background-color: rgba(246, 246, 246, 1);
            position: relative;
            padding: 1px 0px;
            height: 60px;
            top: -3px;
        }

        .pc_change {
            position: absolute;
            left: 60%;
            z-index: 1;
        }

        .pc_change div {
            margin-right: 10px;
            position: relative;
            margin-top: 6%;
            cursor: default;
        }

        .pc_change a:nth-child(2n-1) {
            text-decoration: none;
            color: #000000;
            font-size: 15px;
            cursor: pointer;
        }

        .pc_change a:nth-child(2) {
            font-size: 15px;
        }

        .pc_change a:nth-child(2n-1):hover {
            padding-bottom: 5px;
            border-bottom: 2px solid #265ea2;
            color: #265ea2;
        }

        .pc_change a:nth-child(2n-1):visited {
            padding-bottom: 5px;
            border-bottom: 2px solid #265ea2;
            color: #265ea2;
        }
    }

    .mapWrapper {
        display: flex;
        flex-direction: column;
        /*width: 80%;*/
        margin: auto;
        padding: 0px;

        .investor-index-s1 {
            width: 80%;
            margin: auto;
            padding: 0px;

            .wrapper {
                display: flex;
                flex-direction: column;

                .h3 {
                    color: #333333;
                    font-size: 26px;
                }

                .cont {
                    margin: 2vw 0;
                    display: flex;

                    .img-box {
                        width: 42%;
                        max-width: 640px;
                        overflow: hidden;
                    }

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: transform .3s ease;
                        vertical-align: middle;
                        border: none;
                        margin-top: 3.5%;
                        padding-bottom: 7.5%;
                    }

                    .txt-box {
                        width: 58%;
                        padding-left: 3.125vw;
                        line-height: 3vw;

                        div {
                            /*margin-top: 5px;*/
                            margin-bottom: 5px;
                            text-align: left;
                        }

                        a {
                            color: black;
                            margin-top: 0;
                            font-size: 10px;
                            position: relative;
                            line-height: 24px;
                        }

                        a:link {
                            color: #333333;
                        }

                        a:visited {
                            color: #333333;
                        }

                        a:hover {
                            color: #2f50a2;
                        }

                        .date {
                            display: inline-block;
                            text-align: right;
                            width: 25%;
                        }

                        .content {
                            display: inline-block;
                            text-overflow: clip;
                            text-align: left;
                            width: 75%;
                        }

                        .announcement {
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .inverstor-more {
                    text-decoration: none;
                    width: 120px;
                    line-height: 40px;
                    font-size: 15px;
                    display: block;
                    color: #ffffff;
                    text-align: center;
                    background-image: linear-gradient(90deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
                    transition: all .3s ease;
                }
            }
        }

        .investor-index-s2 {
            width: 80%;
            margin: auto;
            padding: 0px;

            .wrapper {
                margin-top: 30px;
                margin-bottom: 40px;
                width: 103%;
                display: flex;
                flex-direction: column;

                .h3 {
                    font-size: 26px;
                }

                .government_box {
                    margin: 2vw 0;
                    display: flex;

                    .item {
                        margin-right: 14px;
                        display: block;
                        vertical-align: top;
                        width: calc((100% - 6.25vw) / 4);
                        position: relative;
                        background-color: #fff;
                        transition: all .3s ease;
                        cursor: pointer;

                        .img_box {
                            position: relative;
                            opacity: 1;
                            pointer-events: none;
                            z-index: 2;
                            overflow: hidden;
                        }

                        img {
                            display: block;
                            width: 100%;
                            vertical-align: middle;
                            border: none;
                        }

                        span {
                            font-size: 40px;
                            font-family: 'PingFangSC-Medium';
                            color: #e5e5e5;
                            line-height: 1;
                            position: absolute;
                            left: 1.5vw;
                            top: 2vw;
                            z-index: 3;
                            transition: all .3s ease;
                        }

                        .txt-box {
                            position: absolute;
                            left: 1.5vw;
                            right: 1.5vw;
                            top: calc(100% - 2.5vw);
                            transform: translateY(-100%);
                            transition: all .3s ease;
                            z-index: 3;
                            text-align: left;

                            h3 {
                                font-size: 18px;
                                transition: color .3s ease;
                                font-weight: normal;
                            }

                            p {
                                font-size: 14px;
                                olor: #808080;
                                margin-top: 10px;
                                transition: color .3s ease;
                            }
                        }
                    }

                    .item:hover .img_box {
                        opacity: 0;
                    }

                    .item:hover .txt-box {
                        color: #FFFFFF;
                    }

                    .item:hover {
                        background-image: linear-gradient(45deg, #001066, #2f50a2);
                        transform: translateY(-20px);
                        box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);

                        span {
                            display: none;
                        }

                        .txt-box {
                            top: calc(100% - 13.5vw);
                        }
                    }
                }

                .inverstor-more {
                    text-decoration: none;
                    position: relative;
                    left: 80%;
                    width: 120px;
                    line-height: 40px;
                    font-size: 15px;
                    display: block;
                    color: #ffffff;
                    text-align: center;
                    background-image: linear-gradient(10deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
                    transition: all .3s ease;
                }
            }
        }

        .investor-index-s3 {
            margin-top: 30px;
            margin-bottom: 40px;

            .wrapper {
                display: flex;
                flex-direction: column;

                .h3 {
                    font-size: 26px;
                }

                .conts {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url(../../assets/images/relations/achievement_S_back.png);
                    background-attachment: fixed;

                    .cont {
                        padding: 0px;
                        height: 100%;
                        margin: auto;
                        width: 80%;
                        background-color: #fff;
                        display: flex;
                        opacity: 0.9;

                        .tab-tit {
                            /*padding: 3.125vw 0;*/
                            width: 140px;

                            .cell-box {
                                height: 33.33%;
                                position: relative;
                                text-align: center;
                                cursor: pointer;

                                .cell {
                                    margin-top: 25%;
                                    width: 100%;
                                    display: inline-block;
                                    vertical-align: middle;

                                    h3 {
                                        font-size: 18px;
                                    }

                                    h4 {
                                        font-size: 12px;
                                    }
                                }

                            }

                            .item {
                                height: 33.33%;
                                position: relative;
                                text-align: center;
                                cursor: pointer;
                            }

                            .cell-box_active {
                                color: #2f50a2;
                                height: 33.33%;
                                position: relative;
                                text-align: center;
                                cursor: pointer;
                                border-right: 2px solid #2f50a2;

                                .cell {
                                    margin-top: 25%;
                                    width: 100%;
                                    display: inline-block;
                                    vertical-align: middle;

                                    h3 {
                                        font-size: 18px;
                                    }

                                    h4 {
                                        font-size: 12px;
                                    }
                                }
                            }

                        }

                        .tab-cont {
                            text-align: left;
                            width: calc(100% - 140px);
                            border-left: 1px solid #e6e6e6;

                            .child.active {
                                display: block;

                                .more {
                                    text-decoration: none;
                                    font-size: 14px;
                                    color: #29489a;
                                    position: absolute;
                                    right: 1.5vw;
                                    bottom: 1.5vw;
                                }
                            }

                            .child {
                                display: none;
                                position: relative;
                                padding: 3.125vw 4.5vw 4vw;
                            }

                            .item:nth-of-type(1) {
                                margin-top: 0;
                                padding-top: 0;
                                border: none;
                            }

                            .item {
                                margin-top: 1.5vw;
                                padding-top: 1vw;
                                border-top: 1px solid #e6e6e6;
                            }

                            .year {
                                font-size: 34px;
                                line-height: 1;
                                color: #29489a;
                                font-family: 'ziticqtezhanti';
                            }

                            h3 {
                                font-size: 18px;
                                color: #333333;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin-top: 10px;
                            }

                            .btns {
                                margin-top: 15px;

                                a {
                                    text-decoration: none;
                                    width: 120px;
                                    line-height: 40px;
                                    font-size: 15px;
                                    display: inline-block;
                                    vertical-align: top;
                                    color: #808080;
                                    text-align: center;
                                    border: 1px solid #e6e6e6;
                                    margin-right: 20px;
                                }

                                a:link {
                                    color: #333333;
                                }

                                a:visited {
                                    color: #333333;
                                }

                                a:hover {
                                    color: white;
                                    background-image: linear-gradient(10deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
                                }
                            }
                        }
                    }
                }

            }
        }

    }
}

.m_wrapper {
    background: #fff;

    @media screen and (max-width: 479px) {
        .m_firstBannerWrapper {
            width: 100%;
            overflow: hidden;
            position: relative;

            img {
                margin: 0 auto;
                display: block;
                width: 100%;
                // height: 339px;
                height: auto;
            }

            .m_textBox {
                position: absolute;
                top: 35%;
                left: 15px;
                width: 190px;
                height: 70px;
                // background: tomato;
                text-align: left;

                .firstRow1 {
                    color: #c02726;
                    font-size: 16px;
                    font-weight: bolder;
                }

                .firstRow2 {
                    color: #fff;
                    font-size: 16px;
                    font-weight: bolder;
                }

                .firstRow3 {
                    color: #fff;
                    font-size: 12px;
                }
            }
        }

        .m_secondWrapper {
            background: #fff;

            .secTitle {
                font-size: 16px;
                font-family: PingFangSC-Semibold;
                color: #333;
                margin-top: 30px;
                text-align: center;
                margin-bottom: 10px;
            }

            .checkAllDiv {
                // background: green;
                margin-right: 15px;
                text-align: right;
                font-size: 12px;
                color: #999;

                img {
                    width: 6px;
                    height: 10px;
                    margin-left: 5px;
                }
            }

            .m_timeLine {
                width: 80%;
                height: 65px;
                margin: 10px auto;

                // background: rgb(0, 98, 128);
                .m_timeTop {
                    width: 95%;
                    height: 30px;
                    // background: wheat;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;

                    .first_dot {
                        width: 8px;
                        height: 8px;
                        border-radius: 8px;
                        background: #3152d9;
                    }

                    .m_line_item {
                        width: 33.3%;
                        // background: darkcyan;
                        display: flex;
                        align-items: center;

                        .line {
                            width: 100%;
                            height: 1px;
                            background: #d8d9db;
                        }

                        .dot {
                            width: 8px;
                            height: 8px;
                            background: #d8d9db;
                            border-radius: 8px;
                        }
                    }
                }

                .m_timeBottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    // background: palegoldenrod;
                    .timeItem {
                        .yearDiv {
                            font-size: 12px;
                            color: #999;
                        }

                        .highLight {
                            font-size: 14px;
                            color: #3152d9;
                        }
                    }
                }
            }

            .m_sec_content {
                margin: 10px auto;
                width: 90%;
                padding-bottom: 20px;

                // background: green;
                .m_sec_item {
                    background: #f8f8f8;
                    height: 70px;
                    display: flex;
                    margin-top: 6px;
                    border-radius: 5px;

                    .left {
                        width: 60px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 31px;
                            height: 28px;
                        }
                    }

                    .right {
                        flex: 1;
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .top {
                            font-size: 12px;
                            color: #333;
                        }

                        .bottom {
                            font-size: 12px;
                            color: #999;
                        }
                    }
                }
            }
        }

        .m_thirdWrapper {
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            .m_thirdAddress {
                position: absolute;
                left: 28px;
                top: 15px;

                border-radius: 8px;
                // box-shadow: 2px 2px 14px 0px rgba(0, 0, 0, 0.24);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }

            .thirdTop {
                text-align: left;
                font-size: 10px;
                color: #333;
                background: #fff;
                padding: 5px;
                border-radius: 5px;
                font-family: PingFangSC-Regular;
            }

            .thirdBottom {
                width: 0;
                height: 0;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-top: 10px solid #ffffff;
            }


        }
    }
}
</style>
